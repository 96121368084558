import gql from "graphql-tag";
import { query } from "svelte-apollo";

type ContentType = "section" | "output" | "tool";
type IconType= "small" | "medium" | "big";
console.warn("TODO: Retrieve content and icon types");

class IconFetcher {
    private LOCAL_ICON_PREFIX: string = "/img";

    public async getContentIconPath(contentType: ContentType, contentId: string, iconType: IconType) {
        const iconQuery = await query(gql`
            query ${contentType}IconByType($content_id: uuid!) {
                ${contentType}s_icons(where: {_and: {${contentType}_id: {_eq: $content_id}, type: {_eq: ${iconType}}}}) {
                    file {
                        path
                    }
                }
            }
        `, {
            variables: {content_id: contentId}
        }).result();
        if (iconQuery.error || iconQuery.errors)
            console.error(iconQuery.error || iconQuery.errors[0]);
        return this.LOCAL_ICON_PREFIX + iconQuery.data['<CONTENT>s_icons'.replace('<CONTENT>', contentType)][0]?.file?.path;
    }
}

const iconFetcher = new IconFetcher();
export default iconFetcher;